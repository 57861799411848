import { Alert } from '@enterprise-ui/canvas-ui-react'
import React, { ReactNode } from 'react'
import { messages } from './messages'

interface ErrorAlertProps {
  error?: Error | null
  count?: number
  children?: ReactNode
}

export const ErrorAlert: React.FC<ErrorAlertProps> = ({
  error,
  count,
  children,
}) => {
  const isSessionExpired =
    error instanceof Error && error.message.includes('401')

  if (isSessionExpired) {
    return (
      <Alert type="error" heading={error.message}>
        <span>{messages.expiredSession(count)}</span>
        {children}
      </Alert>
    )
  }

  return (
    <Alert type="error" heading={messages.default.heading}>
      <span>{messages.default.text}</span>
      {children}
    </Alert>
  )
}
