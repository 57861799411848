import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchStoreFacts } from 'queries/store-facts/store-facts'
import { StoreFactsFetchProps } from 'types/api'
import { IResponseStoreFacts } from 'utils/api'
import { isStore } from 'utils/location'
import { TIME } from 'utils/misc'

export function useStoreFacts({
  env,
  accessToken,
  location,
}: StoreFactsFetchProps): IResponseStoreFacts {
  const { tenMinutes } = TIME
  const enabled = isStore(location)
  const { data, isLoading, isError, refetch, remove, error } = useQuery(
    [QueryKeys.STORE_FACTS, location],
    () =>
      fetchStoreFacts({
        env,
        accessToken,
        filters: {
          location_id: location.slice(1),
        },
      }),
    {
      staleTime: tenMinutes,
      cacheTime: tenMinutes,
      enabled,
    },
  )

  return { data, isLoading, isError, refetch, remove, error }
}
