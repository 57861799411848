import { Constants, MethodConstants } from './../../constants/constants'
import { useMutation } from '@tanstack/react-query'
import { updateUserContext } from '../../queries/user-context/user-context'
import { UserContextProps } from 'types/api'
import { IAppContext } from 'types/user-context'

export const useCreatePreferences = (
  { lanId, env, accessToken }: UserContextProps,
  onSuccess?: (response: IAppContext) => void,
) => {
  const mutation = useMutation({
    mutationFn: ({ language }: { language: string }) => {
      const data = {
        lanId,
        env,
        accessToken: accessToken,
        method: MethodConstants.POST,
        body: {
          app_id: Constants.APP_ID,
          user_id: lanId,
          app_context: [
            {
              language,
            },
          ],
        },
      }

      return updateUserContext(data)
    },
    onSuccess,
  })

  return {
    createPreferences: mutation.mutate,
    data: mutation.data,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
  }
}
