export const messages = {
  error: 'Looks like something went wrong.',
  nonStoreLocation: 'non-store location table',
  storeLocation: 'store location table',
  payroll: 'payroll table',
  payrollRGD: 'payroll RGD',
  theft: 'theft',
  secure: 'secure',
  Error: 'Error',
}
