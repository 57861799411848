export enum ApiEnvironments {
  PRODUCTION = 'production',
  STAGE = 'stage',
}

export const BaseApiUrls = {
  [ApiEnvironments.PRODUCTION]: 'https://api.target.com',
  [ApiEnvironments.STAGE]: 'https://stage-api.target.com',
}

export enum Apis {
  AGGREGATIONS = 'aggregations',
  STORE_SALES = 'store_sales',
  TEAM_MEMBERS = 'team_members',
  STORE_FACTS = 'store_facts',
  GREENFIELD = 'greenfield',
}

type ApiEnvironmentData<DataType> = Record<ApiEnvironments, DataType>
type ApiData<DataType> = Record<Apis, DataType>

const commonApiKeys: ApiEnvironmentData<string> = {
  [ApiEnvironments.PRODUCTION]: '8bf0373d64f0d49caa4569cba7c3c8270dfa0817',
  [ApiEnvironments.STAGE]: '95e1b34bb85f7eae3e89b2c98ac48f8ce1294406',
}

export const ApiKeys: ApiData<ApiEnvironmentData<string>> = {
  [Apis.AGGREGATIONS]: commonApiKeys,
  [Apis.STORE_SALES]: commonApiKeys,
  [Apis.TEAM_MEMBERS]: commonApiKeys,
  [Apis.STORE_FACTS]: commonApiKeys,
  [Apis.GREENFIELD]: commonApiKeys,
}

export const ApiSubUrls: ApiData<string> = {
  [Apis.AGGREGATIONS]: '/mpm_aggregations/v1',
  [Apis.STORE_SALES]: '/store_sales/v2/',
  [Apis.TEAM_MEMBERS]: '/mpm_team_members/v2/users',
  [Apis.STORE_FACTS]: '/store_leaders/v1/storefacts',
  [Apis.GREENFIELD]: '/bi_reporting_assets/v3/execute_cards',
}
