import { Grid, Placeholder } from '@enterprise-ui/canvas-ui-react'
import { v4 as uuidv4 } from 'uuid'

type BasicPlaceholderType = {
  type?: 'text' | 'table'
  rows?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
  columns?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
}

export const BasicPlaceholder = ({
  type = 'text',
  rows = 1,
  columns = 1,
}: BasicPlaceholderType) => {
  if (type === 'text') {
    return <BasicPlaceholder.Text rows={rows} />
  } else if (type === 'table') {
    return <BasicPlaceholder.Table rows={rows} columns={columns} />
  }
}

BasicPlaceholder.Text = ({ rows = 1 }: BasicPlaceholderType) => {
  let rowsArr = Array.from({ length: rows }, (_, i) => i)
  return (
    <Placeholder>
      <Grid.Container spacing="none">
        <Grid.Item xs={12}>
          {rowsArr.map((row, index) => (
            <Grid.Container key={uuidv4()}>
              <Grid.Item xs={12}>
                <Placeholder.Text emphasized />
              </Grid.Item>
            </Grid.Container>
          ))}
        </Grid.Item>
      </Grid.Container>
    </Placeholder>
  )
}

BasicPlaceholder.Table = ({ rows = 5, columns = 4 }: BasicPlaceholderType) => {
  let rowsArr = Array.from({ length: rows }, (_, i) => i)
  let columnsArr = Array.from({ length: columns }, (_, i) => i)
  return (
    <Placeholder>
      {rowsArr.map(() => {
        return (
          <Grid.Container spacing="dense" key={uuidv4()}>
            {columnsArr.map(() => {
              return (
                <Grid.Item xs key={uuidv4()}>
                  <Placeholder.Text height="65" />
                </Grid.Item>
              )
            })}
          </Grid.Container>
        )
      })}
    </Placeholder>
  )
}
