import { useEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import { usePreferences } from './usePreferences'
import { useMPMEnv } from 'utils/env'
import { useUser } from 'components/user-context'
import { IPreferences } from 'types/user-context'

export const useSetPreferences = (): IPreferences => {
  const { session } = useAuth()

  const { lanId } = session?.userInfo ?? {
    firstName: '',
    lanId: '',
  }
  const env = useMPMEnv()
  const { preferences, setPreferences } = useUser()
  const { data } = usePreferences({
    lanId,
    env,
    accessToken: session?.accessToken ?? '',
  })

  useEffect(() => {
    if (data) {
      const { user_contexts } = data
      if (user_contexts?.length) {
        setPreferences(data)
      }
    }
  }, [data, setPreferences])

  return preferences
}
