import { Grid } from '@enterprise-ui/canvas-ui-react'
import { BasicPlaceholder } from 'components/placeholder'
import { useTranslation } from 'react-i18next'
import { Cell, formatNegative, getNegativeStyle } from 'utils/data'

interface IGridData {
  isLoading: boolean
  data: Cell[]
}

export const GridData = ({ isLoading, data }: IGridData) => {
  const { t } = useTranslation()
  if (isLoading) {
    return (
      <div className="placeholder-container" data-testid="placeholder-grid">
        <BasicPlaceholder type="text" rows={3} />
      </div>
    )
  }

  const [projectedFlexHoursVal, guaranteedFlexHoursVal, plannedFlexHoursVal] =
    data
  return (
    <>
      <Grid.Item xs={12} className="grid-item hc-pv-normal">
        <p className="hc-fs-sm hc-ma-none">{t('proj flex hrs')}</p>
        <p className={getNegativeStyle(projectedFlexHoursVal?.value)}>
          {formatNegative(projectedFlexHoursVal?.value)}
        </p>
      </Grid.Item>
      <Grid.Item xs={12} className="grid-item hc-pb-normal">
        <p className="hc-fs-sm hc-ma-none">{t('guaranteed flex hrs')}</p>
        <p className={getNegativeStyle(guaranteedFlexHoursVal?.value)}>
          {formatNegative(guaranteedFlexHoursVal?.value)}
        </p>
      </Grid.Item>
      <Grid.Item xs={12} className="grid-item hc-pb-expanded">
        <p className="hc-fs-sm hc-ma-none">{t('b/(p) flex to plan')}</p>
        <p className={getNegativeStyle(plannedFlexHoursVal?.value)}>
          {formatNegative(plannedFlexHoursVal?.value)}
        </p>
      </Grid.Item>
    </>
  )
}
