import { useMemo } from 'react'
import type { IResult } from 'ua-parser-js'

interface DeviceInfo {
  deviceType:
    | 'mobile'
    | 'tablet'
    | 'desktop'
    | 'smarttv'
    | 'wearable'
    | 'embedded'
    | 'unknown'
  osName: string
  browserName: string
  isAppleDevice: boolean
  isAndroidDevice: boolean
  isZebraDevice: boolean
}

export const useDeviceInfo = (userAgentResult: IResult): DeviceInfo => {
  return useMemo(() => {
    const deviceType =
      (userAgentResult.device.type as DeviceInfo['deviceType']) || 'desktop'
    const osName = userAgentResult.os.name || 'Unknown'
    const browserName = userAgentResult.browser.name || 'Unknown'

    return {
      deviceType,
      osName,
      browserName,
      isAppleDevice: /iOS|Mac OS/.test(osName),
      isAndroidDevice: /Android/.test(osName),
      isZebraDevice: /Zebra/.test(userAgentResult.device.vendor || ''),
    }
  }, [userAgentResult])
}
