export enum QueryKeys {
  USER_DETAILS = 'USER_DETAILS',
  NEARBY_LOCATIONS = 'NEARBY_LOCATIONS',
  PAYROLL_LOCATION = 'PAYROLL_LOCATION',
  PAYROLL_LOCATION_WEEKLY = 'PAYROLL_LOCATION_WEEKLY',
  PAYROLL_LOCATION_DAILY = 'PAYROLL_LOCATION_DAILY',
  PAYROLL_WORKGROUP = 'PAYROLL_WORKGROUP',
  PAYROLL_WORKGROUP_DRILL = 'PAYROLL_WORKGROUP_DRILL',
  PAYROLL_OVERTIME = 'PAYROLL_OVERTIME',
  SHORTAGE = 'SHORTAGE',
  SHORTAGE_TOP_TEN = 'SHORTAGE_TOP_TEN',
  SHORTAGE_MERCH = 'SHORTAGE_MERCH',
  SAFETY_LAGGING = 'SAFETY_LAGGING',
  SAFETY_LEADING = 'SAFETY_LEADING',
  SAFETY_TOTAL = 'SAFETY_TOTAL',
  SAFETY_STORE = 'SAFETY_STORE',
  LOCATION_HIERARCHY = 'LOCATION_HIERARCHY',
  SALES_OVERVIEW = 'SALES_OVERVIEW',
  STORE_FACTS = 'STORE_FACTS',
  STORE_LEADERSHIP = 'STORE_LEADERSHIP',
  USER_CONTEXT = 'USER_CONTEXT',
  USER_CONTEXT_CREATE = 'USER_CONTEXT_CREATE',
  USER_CONTEXT_UPDATE = 'USER_CONTEXT_UPDATE',
  ASSETS_PROTECTION_STORE = 'ASSETS_PROTECTION_STORE',
}
