import { Button } from '@enterprise-ui/canvas-ui-react'

interface IInteractiveButton {
  children: React.ReactNode
  style: string
  onClick?: (value: string) => void
}

export const InteractiveButton = ({
  children,
  style,
  onClick,
}: IInteractiveButton) => (
  <Button
    type="ghost"
    className={`hc-ph-none hc-pv-none hc-ta-left ${style}`}
    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(e.currentTarget.textContent || '')
      }
    }}
  >
    {children}
  </Button>
)
