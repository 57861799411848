import { useQueries } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchSalesOverview } from 'queries/sales/overview'
import { SalesFetchProps } from 'types/api'
import { TIME } from 'utils/misc'

export function useSalesOverview({
  env,
  accessToken,
  dynamicSubPath,
  location,
  filters,
}: SalesFetchProps): any[] {
  const { tenMinutes } = TIME

  // set time dimensions to query
  const timeDimensions = ['tdy', 'yst', 'wtd', 'lastweek', 'mtd', 'ytd']

  // create queries for each time dimension
  const queries = timeDimensions.map((dimension) => {
    return {
      queryKey: [QueryKeys.SALES_OVERVIEW, location, dimension],
      queryFn: () =>
        fetchSalesOverview({
          env,
          accessToken,
          dynamicSubPath,
          filters: { ...filters, time_dimension: dimension },
        }),
      staleTime: tenMinutes,
      cacheTime: tenMinutes,
      retry: false,
    }
  })

  return useQueries({
    queries,
  })
}
