import { Layout } from 'components/layout'
import { messages } from './messages'

export const Unauthorized = () => {
  return (
    <Layout heading="Unauthorized">
      <p>{messages.unauthorized}</p>
    </Layout>
  )
}
