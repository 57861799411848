import { useState } from 'react'
import { useAuth } from '@praxis/component-auth'
import { Table } from '@enterprise-ui/canvas-ui-react'
import { useSearchParams } from 'react-router-dom'
import { useUser } from 'components/user-context'
import {
  usePayrollWorkgroup,
  usePayrollWorkgroupDrill,
} from 'hooks/payroll/workgroup'
import { useMPMEnv } from 'utils/env'
import {
  TableData,
  addLinkToFirstColumn,
  buildDataTableObject,
  insertRows,
  removeLinkFromFirstColumn,
} from 'utils/data'
import { getTableFormat } from 'utils/table'
import { Container } from 'components/container/Container'
import { UrlParams } from 'enums/url-params'
import { BasicPlaceholder } from 'components/placeholder'
import { useTranslation } from 'react-i18next'
import { messages } from '../messages'
import useRefresh from 'hooks/utils/useRefresh'
import useCountdown from 'hooks/utils/useCountdown'
import { ErrorAlert } from 'components/error-fallback'

export const Workgroup = () => {
  const { t } = useTranslation('payroll')
  const [params] = useSearchParams()
  const { location } = useUser()
  const env = useMPMEnv()
  const { logout, session } = useAuth()
  const [parent, setParent] = useState<string>('')
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName
  let { data, isLoading, isError, refetch, remove, error } =
    usePayrollWorkgroup({
      env,
      accessToken: session?.accessToken ?? '',
      location: currentLocation,
    })
  useRefresh(refetch, remove)
  const count = useCountdown(5, logout, error as Error)

  const { data: subData } = usePayrollWorkgroupDrill({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    dynamicSubPath: parent,
  })

  if (isLoading) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="text" rows={1} />
        <BasicPlaceholder type="table" rows={8} columns={3} />
      </div>
    )
  }

  if (isError) {
    return <ErrorAlert error={error as Error} count={count} />
  }
  const widths = [120, ...getTableFormat(data?.rows?.length ?? 20, 105)]

  const addChildRows = (data: TableData, subData: TableData) => {
    subData.rows = removeLinkFromFirstColumn(subData.rows)
    subData.rows = subData.rows.map((row) => {
      row[0].childOf = parent

      return row
    })
    const insertIndex =
      data.rows.findIndex((row) => row[0].value === parent) + 1
    const newData = { ...data }
    let newDataRows = [...data.rows]
    insertRows(newDataRows, insertIndex, subData.rows)
    newData.rows = newDataRows
    data = newData

    return data
  }
  if (data) {
    data.rows = addLinkToFirstColumn(data.rows)
    if (subData) {
      data = addChildRows(data, subData)
    }
  }

  const handleClick = (parentValue: string) => {
    if (data) {
      const match = data?.rows.filter((row) => row[0].childOf === parentValue)
      if (parent === parentValue || match.length > 0) {
        const filtered = data?.rows.filter(
          (row) => row[0].childOf !== parentValue,
        )
        data.rows = filtered
        setParent('')
      } else {
        setParent(parentValue)
      }
    }
  }

  return (
    <Container>
      <p className="hc-fs-md">planned hours by work group</p>
      <Table
        data={buildDataTableObject({
          currentData: data!,
          widths,
          handleClick,
          pinFirstColumn: true,
        })}
        scrollableWidth={true}
        name={t(messages.workgroup, { ns: 'payroll' })}
      />
    </Container>
  )
}
