import { createContext, useState, useContext } from 'react'
import { IPreferences } from 'types/user-context'
import { IResult, UAParser } from 'ua-parser-js'

export interface Location {
  locationId: string
  shortName: string
  name: string
}

export interface IUserContext {
  location: Location
  setLocation: (location: Location) => void
  greenfieldGroups: string[] | null
  setGreenfieldGroups: (greenfieldGroups: string[]) => void
  preferences: IPreferences
  setPreferences: (preferences: IPreferences) => void
  userAgent: IResult
  setUserAgent: (userAgent: IResult) => void
}

export const UserContext = createContext<IUserContext | undefined>(undefined)

interface IUserProviderProps {
  location?: string
  children: JSX.Element | JSX.Element[] | string
}

export const UserProvider = ({ children }: IUserProviderProps) => {
  const [location, setLocation] = useState<Location>({
    locationId: '',
    shortName: '',
    name: '',
  })

  const [greenfieldGroups, setGreenfieldGroups] = useState<string[]>([])
  const [preferences, setPreferences] = useState<IPreferences>({
    app_id: '',
    count: 0,
    page: 0,
    per_page: 0,
    user_id: '',
    user_contexts: [],
  })

  const [userAgent, setUserAgent] = useState<IResult>(
    new UAParser().getResult(),
  )

  const userProviderContextValue = {
    location,
    setLocation,
    greenfieldGroups,
    setGreenfieldGroups,
    preferences,
    setPreferences,
    userAgent,
    setUserAgent,
  }

  return (
    <UserContext.Provider value={userProviderContextValue}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const userContext = useContext(UserContext)

  if (userContext === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }

  return userContext
}
