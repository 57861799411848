const events = {
  refresh: 'refresh',
}
const globalEventTarget = new EventTarget()

export const emitPullDownRefreshEvent = () => {
  const event = new Event(events.refresh)
  globalEventTarget.dispatchEvent(event)
}

export const listenForPullDownRefresh = (callback: () => void) => {
  globalEventTarget.addEventListener(events.refresh, callback)
}

export const removePullDownListener = (callback: () => void) => {
  globalEventTarget.removeEventListener(events.refresh, callback)
}
