import { TableData } from 'utils/data'
import { ApiSubUrls, Apis } from 'enums/api-constants'
import { fetchGeneralData } from 'queries/fetchData'

export const fetchPayrollLocationMonthlyData = fetchGeneralData<TableData>(
  `${ApiSubUrls[Apis.AGGREGATIONS]}/payroll/location/monthly`,
)
export const fetchPayrollLocationWeeklyData = fetchGeneralData<TableData>(
  `${ApiSubUrls[Apis.AGGREGATIONS]}/payroll/location/weekly`,
)
export const fetchPayrollLocationDailyData = fetchGeneralData<TableData>(
  `${ApiSubUrls[Apis.AGGREGATIONS]}/payroll/location/daily`,
)
