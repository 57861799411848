import { Layout } from 'components/layout'
import { useUser } from 'components/user-context'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { UrlParams } from 'enums/url-params'
import { isStore } from 'utils/location'
import { ShortageStore } from './shortage-store'
import { ShortageNonStore } from './shortage-nonstore'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/error-fallback'

export const ShortagePage = () => {
  const [params] = useSearchParams()
  const { location } = useUser()
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName

  return <Shortage key={currentLocation} />
}

const Shortage = () => {
  const { t } = useTranslation()
  const [params] = useSearchParams()
  const { location } = useUser()
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName
  const isStoreFlag = isStore(currentLocation)

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <ErrorFallback
          error={error}
          resetErrorBoundary={resetErrorBoundary}
          heading={t('shortage')}
        />
      )}
    >
      <Layout heading={t('shortage')}>
        <div className="pwa shortage">
          {isStoreFlag ? (
            <ShortageStore key={currentLocation} />
          ) : (
            <ShortageNonStore key={currentLocation} />
          )}
        </div>
      </Layout>
    </ErrorBoundary>
  )
}
