import { useAuth } from '@praxis/component-auth'
import { Divider } from '@enterprise-ui/canvas-ui-react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { useUser } from 'components/user-context'
import {
  addHyperLinkToFirstColumn,
  getDynamicSubPath,
  getFormattedReportDate,
  getHeaderText,
  getLocationFlexHours,
  getFormattedDateMonthandYear,
} from '../helpers'
import { useMPMEnv } from 'utils/env'
import { isStore as isStoreCheck } from 'utils/location'
import { Cell } from 'utils/data'
import { Container } from 'components/container/Container'
import { usePayrollLocationMonthly } from 'hooks/payroll/location'
import { UrlParams } from 'enums/url-params'
import { NonStoreLocation } from './NonStoreLocation'
import { StoreLocation } from './StoreLocation'
import { GridData } from './GridData'
import { useTranslation } from 'react-i18next'
import { getLocationFilters } from 'utils/api'
import useRefresh from 'hooks/utils/useRefresh'
import useCountdown from 'hooks/utils/useCountdown'
import { ErrorAlert } from 'components/error-fallback'

export const Location = () => {
  const { t } = useTranslation()
  const [params] = useSearchParams()
  const { location } = useUser()
  const env = useMPMEnv()
  const { logout, session } = useAuth()
  const { pathname } = useLocation()
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName

  let projectedFlexHoursVal: Cell = { value: 'n/a' },
    guaranteedFlexHoursVal: Cell = { value: 'n/a' },
    plannedFlexHoursVal: Cell = { value: 'n/a' }

  /* RGD-level Data */
  const filters = isStoreCheck(currentLocation)
    ? getLocationFilters(currentLocation)
    : undefined
  const dynamicSubPath = getDynamicSubPath(currentLocation)
  let { data, isLoading, isError, refetch, remove, error } =
    usePayrollLocationMonthly({
      env,
      accessToken: session?.accessToken ?? '',
      location: currentLocation,
      dynamicSubPath,
      filters,
    })
  useRefresh(refetch, remove)
  const count = useCountdown(5, logout, error as Error)

  if (isError) {
    return <ErrorAlert error={error as Error} count={count} />
  }

  if (data) {
    data = addHyperLinkToFirstColumn(data, currentLocation, pathname)
    const { footer } = data
    if (footer) {
      const { projectedFlexHours, guaranteedFlexHours, plannedFlexHours } =
        getLocationFlexHours(currentLocation, footer)
      projectedFlexHoursVal = projectedFlexHours
      guaranteedFlexHoursVal = guaranteedFlexHours
      plannedFlexHoursVal = plannedFlexHours
    }
  }

  const isStore = isStoreCheck(currentLocation)
  const yearMonthDate = getFormattedDateMonthandYear()

  return (
    <Container>
      <p className="hc-fs-md hc-ma-none">{t(getHeaderText(currentLocation))}</p>
      <p className="hc-fs-sm hc-ma-none2">
        {t('as of')} {getFormattedReportDate()}
      </p>
      <Divider />
      <GridData
        isLoading={isLoading}
        data={[
          projectedFlexHoursVal,
          guaranteedFlexHoursVal,
          plannedFlexHoursVal,
        ]}
      />
      {!isStore && (
        <NonStoreLocation
          data={data}
          isLoading={isLoading}
          isError={isError}
          currentLocation={currentLocation}
          bottomLeftCell={yearMonthDate}
          error={error as Error}
          count={count}
        />
      )}
      {isStore && (
        <StoreLocation
          isLoading={isLoading}
          isError={isError}
          currentLocation={currentLocation}
          bottomLeftCell={yearMonthDate}
          error={error as Error}
          count={count}
        />
      )}
    </Container>
  )
}
