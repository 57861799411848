import { Location } from 'components/user-context'
import {
  LocationHierarchyKeys,
  LocationHierarchyModel,
} from 'queries/locationHierarchy/locationHierarchy'
import { isDistrict, isGroup, isRGD, isRegion, isStore } from 'utils/location'

export const getHeaderLocation = (location: string) => {
  if (isRGD(location)) {
    const locationId = location.slice(1)
    if (isRegion(location)) {
      return 'Region ' + locationId
    } else if (isGroup(location)) {
      return 'Group ' + locationId
    } else if (isDistrict(location)) {
      return 'District ' + locationId
    }
  } else if (!isStore(location)) {
    return 'Target'
  }

  return location
}

type BreadcrumbsType = {
  location: Location
  currentLocation: string
  locationData: LocationHierarchyModel
}
const getBreadcrumbLinkLabel = (key: LocationHierarchyKeys, id: number) => {
  key = key.toUpperCase() as LocationHierarchyKeys
  if (key.startsWith('R')) return 'R' + id
  if (key.startsWith('G')) return 'G' + id
  if (key.startsWith('D')) return 'D' + id
  if (key.startsWith('S')) return 'T' + id.toString().padStart(4, '0')

  return 'Target'
}
export const getBreadcrumbs = ({
  location,
  currentLocation,
  locationData,
}: BreadcrumbsType) => {
  let breadcrumbs: LocationHierarchyKeys[] = []
  if (locationData?.location_hierarchy) {
    breadcrumbs = ['region_id', 'group_id', 'district_id']
    if (isStore(currentLocation)) {
      breadcrumbs.push('store_id')
    }

    const locationFirstChar = location.shortName.charAt(0).toLowerCase()
    const index = breadcrumbs.findIndex((key) =>
      key.startsWith(locationFirstChar),
    )
    if (index > -1) {
      breadcrumbs = breadcrumbs.slice(index)
    }
  }

  const breadcrumbList = []
  const userLocation = location.shortName
  const isDrilledDown = userLocation !== currentLocation
  if (isDrilledDown) {
    if (
      userLocation.includes('Company') ||
      (userLocation.includes('Target') && userLocation !== currentLocation)
    ) {
      breadcrumbList.push({
        label: 'Target',
        link: '',
      })
    }
    for (const key of breadcrumbs) {
      const id = locationData?.location_hierarchy[key] ?? -1
      const value = getBreadcrumbLinkLabel(key, id)
      if (id > -1) {
        breadcrumbList.push({
          label: value,
          link: `?locationId=${value}`,
        })
      }
    }
  }

  return breadcrumbList
}
