import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query'
import { LocationHierarchyModel } from 'queries/locationHierarchy/locationHierarchy'
import { TableData, TitledTableData } from './data'
import {
  isRegion,
  isStore,
  isGroup,
  isDistrict,
  isStoreOrRGD,
} from 'utils/location'
import { StoreFactsData } from 'pages/store-facts/utils/store-facts'
import { IAppContext, IPreferences } from 'types/user-context'

export type RefetchType<TData> = <TPageData>(
  options?: RefetchOptions & RefetchQueryFilters<TPageData>,
) => Promise<QueryObserverResult<TData, unknown>>

export type IGenericRefetchType<TData> = <TPageData>(
  options?: RefetchOptions & RefetchQueryFilters<TPageData>,
) => Promise<QueryObserverResult<TData, unknown>>
export type IPreferencesResponse = IGenericResponse<IPreferences>
export type IAppContextResponse = IGenericResponse<IAppContext>

export interface IGenericResponse<TData> {
  data: TData | undefined
  isLoading: boolean
  isError: boolean
  refetch: IGenericRefetchType<TData>
  remove: () => void
  error: unknown
}
export type IResponse = IGenericResponse<TableData>
export type ITitleListResponse = IGenericResponse<TitledTableData[]>
export type ILocationHierarchyResponse =
  IGenericResponse<LocationHierarchyModel>
export type IResponseStoreFacts = IGenericResponse<StoreFactsData>

export const getLocationFilters = (location: string) => {
  if (!isStoreOrRGD(location)) {
    return undefined
  }

  let filters = {} as { [key: string]: string }
  if (isRegion(location)) {
    filters.filter_dimension = 'regions'
  } else if (isGroup(location)) {
    filters.filter_dimension = 'groups'
  } else if (isDistrict(location)) {
    filters.filter_dimension = 'districts'
  } else if (isStore(location)) {
    filters.filter_dimension = 'store'
  }
  filters.filter_value = location

  return filters
}

export const apiNotEnabled = () => ({
  data: undefined,
  isLoading: false,
  isError: false,
  refetch: () =>
    Promise.resolve({
      data: undefined,
      error: null,
      status: 'success', // You can use 'idle' or 'success' depending on your logic
      isStale: false,
      isFetching: false,
      isPaused: false,
      refetch: () => Promise.resolve({ data: undefined, error: null }), // Nested refetch result
    } as unknown as QueryObserverResult<TableData, unknown>),
  remove: () => {},
  error: undefined,
})

export const mockApiNotEnabled = () => ({
  data: undefined,
  isLoading: false,
  isError: false,
  refetch: jest.fn().mockResolvedValue({ data: undefined }),
  remove: jest.fn(),
  error: undefined,
})
