import { Button, Heading } from '@enterprise-ui/canvas-ui-react'
import {
  MenuIcon,
  SearchIcon,
  EnterpriseIcon,
  BellIcon,
} from '@enterprise-ui/icons'
import './loginPlaceholder.css'
import { messages } from './messages'

export const LoginPlaceholder = () => {
  return (
    <div className="hc-pa-none" data-testid="login-placeholder">
      <div className="navbar-container">
        <Button
          iconOnly
          className="navbar-menu-button"
          aria-label={messages.open}
          type="ghost"
        >
          <EnterpriseIcon icon={MenuIcon} size="xl" className="navbar-icon" />
        </Button>
        <div className="navbar-heading">
          <Heading className="kpi-heading hc-fs-xl">&nbsp;</Heading>
          <Heading className="location-heading hc-fs-xs hc-lh-normal">
            &nbsp;
          </Heading>
        </div>
        <div className="navbar-container-right">
          <Button
            iconOnly
            className="navbar-menu-button navbar-alerts"
            aria-label={messages.view}
            type="ghost"
          >
            <EnterpriseIcon icon={BellIcon} size="xl" className="navbar-icon" />
          </Button>
          <Button
            iconOnly
            className="navbar-menu-button"
            type="ghost"
            aria-label={messages.search}
          >
            <EnterpriseIcon
              icon={SearchIcon}
              size="xl"
              className="navbar-icon"
            />
          </Button>
        </div>
      </div>
      <div className="children-container-classes" />
    </div>
  )
}
