import React, { createContext, useEffect, useState } from 'react'
import { logEvent, LogLevel } from '@praxis/component-logging'
import Firebase from 'firebase/compat/app'
import 'firebase/compat/remote-config'
import { useMPMEnv } from 'utils/env'

type FeatureFlags = Record<string, boolean>
const defaultFeatureFlags: FeatureFlags = {
  greenfieldLinkEnabled: true,
  settingsLinkEnabled: true,
  salesLinkEnabled: true,
  assetsProtectionEnabled: true,
  brandsEnabled: true,
  loyaltyEnabled: true,
  notesEnabled: true,
  opsMetricsEnabled: true,
  storeFulfillmentEnabled: true,
  storeInfoEnabled: true,
  trailerEnabled: true,
}

const FlagsContext = createContext<FeatureFlags>(defaultFeatureFlags)

type FlagsType = {
  children: React.ReactNode
}

export const FlagsProvider = ({ children }: FlagsType) => {
  const { firebase: firebaseConfig, environment } = useMPMEnv()
  const [flags, setFlags] = useState<FeatureFlags>({})

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const firebase = Firebase.initializeApp(firebaseConfig)

        const remoteConfig = firebase.remoteConfig()
        remoteConfig.settings = {
          minimumFetchIntervalMillis: 5000,
          fetchTimeoutMillis: 5000,
        }
        await remoteConfig.fetchAndActivate()
        const remoteFeatureFlags = Object.fromEntries(
          Object.entries(remoteConfig.getAll()).map(([key, value]) => [
            key,
            value.asBoolean(),
          ]),
        )

        const featureFlags: FeatureFlags = {
          ...defaultFeatureFlags,
          ...remoteFeatureFlags,
        }
        setFlags(environment !== 'local' ? featureFlags : defaultFeatureFlags)
      } catch (error) {
        logEvent(
          { message: 'Error instantiating Firebase Remote Config' },
          { level: LogLevel.Error },
        )
        setFlags(defaultFeatureFlags)
      }
    }

    fetchConfig()
  }, [firebaseConfig, environment])

  return <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
}

export const useFlags = () => {
  const context = React.useContext(FlagsContext)

  return context
}

export default FlagsProvider
