import { useState } from 'react'
import { Button, Grid, Table } from '@enterprise-ui/canvas-ui-react'
import { TableData, buildDataTableObject } from 'utils/data'
import { useTranslation } from 'react-i18next'

type TitledFilteredTableProps = {
  title: string
  data: TableData
  widths: number[]
  selectedCell?: string
  handleClick?: (value: string) => void
  pinFirstColumn?: boolean
}

export const TitledFilteredTable = ({
  title,
  data,
  widths,
  handleClick,
  pinFirstColumn,
  selectedCell,
}: TitledFilteredTableProps) => {
  const { t } = useTranslation()
  let tempData = { ...data }
  const options = ['$', '%']
  const [currentIndex, setCurrentIndex] = useState(0)
  const selectedOption = options[currentIndex]
  const handleToggle = () => setCurrentIndex((prevIndex) => 1 - prevIndex)

  tempData.headings = tempData.headings
    ?.filter((r, i) => i === 0 || r.value.includes(selectedOption))
    .map((heading) => {
      return { ...heading, value: t(heading.value) }
    })
  tempData.rows = tempData.rows?.map((row) => {
    return row.filter(
      (cell, index) => index === 0 || cell.value.includes(selectedOption),
    )
  })
  tempData.footer = tempData.footer
    ?.filter((r, i) => i === 0 || r.value.includes(selectedOption))
    .map((footer) => {
      return { ...footer, value: t(footer.value) }
    })
  const viewLabel = `view in ${currentIndex === 0 ? '%' : '$'}`

  return (
    <>
      <Grid.Item xs={8} className="hc-pb-none">
        <p className="hc-pa-min hc-lh-expanded">{title}</p>
      </Grid.Item>
      <Grid.Item xs={4} className="hc-pb-none hc-ta-right">
        <Button type="ghost" onClick={handleToggle}>
          {t(viewLabel)}
        </Button>
      </Grid.Item>
      <Grid.Item xs={12} className="hc-pt-none">
        <Table
          data={buildDataTableObject({
            currentData: tempData,
            widths,
            handleClick,
            pinFirstColumn,
            selectedCell,
          })}
          name={title}
          scrollableWidth={true}
        />
      </Grid.Item>
    </>
  )
}
