import { useAuth } from '@praxis/component-auth'
import { Table } from '@enterprise-ui/canvas-ui-react'
import { Container } from 'components/container/Container'
import { buildDataTableObject } from 'utils/data'
import {
  addHyperLinkToFirstColumn,
  getDynamicSubPath,
  setFirstColumnInHeaders,
} from './helpers'
import { useSafetyLagging } from 'hooks/safety/useSafetyLagging'
import { useTranslation } from 'react-i18next'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useUser } from 'components/user-context'
import { useMPMEnv } from 'utils/env'
import { UrlParams } from 'enums/url-params'
import { getTableFormat } from 'utils/table'
import { BasicPlaceholder } from 'components/placeholder'
import { messages } from './messages'
import useRefresh from 'hooks/utils/useRefresh'
import useCountdown from 'hooks/utils/useCountdown'
import { ErrorAlert } from 'components/error-fallback/error-alert'

export const SafetyLagging = () => {
  const { t } = useTranslation()
  const [params] = useSearchParams()
  const { location } = useUser()
  const env = useMPMEnv()
  const { logout, session } = useAuth()
  const { pathname } = useLocation()
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName

  const dynamicSubPath = getDynamicSubPath(currentLocation, false) || 'Company'
  let { data, isLoading, isError, refetch, remove, error } = useSafetyLagging({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    dynamicSubPath,
  })
  useRefresh(refetch, remove)
  const count = useCountdown(5, logout, error as Error)

  if (isLoading) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="table" rows={5} columns={5} />
      </div>
    )
  }

  if (isError || !data) {
    return <ErrorAlert error={error as Error} count={count} />
  }

  data = data!

  data.headings = setFirstColumnInHeaders(data, 'Location')
  data = addHyperLinkToFirstColumn(data, pathname)

  data.headings = data.headings?.map((heading) => {
    return { ...heading, value: t(heading.value) }
  })

  return (
    <Container>
      <p>{t(messages.nonStoreHeading)}</p>
      <Table
        data={buildDataTableObject({
          currentData: data,
          widths: [100, ...getTableFormat(data?.rows?.length ?? 20, 100)],
          pinFirstColumn: true,
        })}
        name={t(messages.laggingTitle)}
        scrollableWidth={true}
      />
    </Container>
  )
}
