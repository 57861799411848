import { useMutation } from '@tanstack/react-query'
import { updateUserContext } from '../../queries/user-context/user-context'
import { PreferencesUpdateProps } from 'types/api'
import { Constants, MethodConstants } from 'constants/constants'

export const useUpdatePreferences = ({
  lanId,
  env,
  accessToken,
  preferences,
}: PreferencesUpdateProps) => {
  const mutation = useMutation({
    mutationFn: ({ language }: { language: string }) => {
      const data = {
        lanId,
        env,
        accessToken: accessToken,
        method: MethodConstants.PUT,
        body: {
          app_id: Constants.APP_ID,
          user_id: lanId,
          app_context: [
            {
              language,
            },
          ],
        },
        dynamicSubPath: preferences.user_contexts[0].context_id,
      }

      return updateUserContext(data)
    },
  })

  return {
    updatePreferences: mutation.mutate,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
  }
}
