import React from 'react'
import { Grid, GridContainerProps } from '@enterprise-ui/canvas-ui-react'

/**
 * Simple full width container helper, using canvas grid system
 *
 * @param props same as [GridContainerProps]
 * @returns
 */
export function Container(props: GridContainerProps) {
  return (
    // note that {...props} must come last to override this className
    <Grid.Container className="hc-pa-min" {...props}>
      <Grid.Item xs={12}>{props.children}</Grid.Item>
    </Grid.Container>
  )
}
