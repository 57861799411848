import { ApiSubUrls, Apis } from 'enums/api-constants'
import { fetchGeneralData } from 'queries/fetchData'
import { TableData, TitledTableData } from 'utils/data'

export const fetchSafetyLagging = fetchGeneralData<TableData>(
  `${ApiSubUrls[Apis.AGGREGATIONS]}/safety/lagging/`,
)
export const fetchSafetyTotal = fetchGeneralData<TableData>(
  `${ApiSubUrls[Apis.AGGREGATIONS]}/safety/total/`,
)
export const fetchSafetyLeading = fetchGeneralData<TableData>(
  `${ApiSubUrls[Apis.AGGREGATIONS]}/safety/leading/`,
)
export const fetchSafetyStore = fetchGeneralData<TitledTableData[]>(
  `${ApiSubUrls[Apis.AGGREGATIONS]}/safety/store/`,
)
