export const messages = {
  info: 'info',
  you: 'you',
  location: 'location',
  version: 'version',
  help: 'help',
  feedback: 'feedback',
  error: 'Looks like something went wrong updating user preferences.',
  language: 'language',
  languageOptions: {
    en: 'en',
    es: 'es',
    EN: 'EN',
    ES: 'ES',
  },
  creating: 'creating user preferences...',
  updating: 'updating user preferences...',
  loading: 'loading',
}
