/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import {
  Button,
  ButtonGroup,
  Card,
  Divider,
  Grid,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import {
  ProfileIcon,
  StoreIcon,
  HelpIcon,
  ChatIcon,
  InfoFilledIcon,
  TextStringIcon,
} from '@enterprise-ui/icons'
import { useAuth } from '@praxis/component-auth'

import {
  FloatingActionButton,
  FloatingActionButtonContainer,
} from 'components/floating-action-button'
import { SplitRow } from 'components/grid'
import { Layout } from 'components/layout'
import { useUser } from 'components/user-context'
import { useTranslation } from 'react-i18next'
import { useMPMEnv } from 'utils/env'
import { useUpdatePreferences } from '../../hooks/user-context/useUpdatePreferences'
import { useCreatePreferences } from '../../hooks/user-context/useCreatePreferences'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/error-fallback'
import { messages } from './messages'

export const SettingsPage = () => {
  const { location, preferences, setPreferences } = useUser()
  const [language, setLanguage] = useState<string>(
    preferences?.user_contexts?.[0]?.app_context?.language || 'en',
  )
  const { session } = useAuth()

  const { firstName, lanId } = session?.userInfo ?? {
    firstName: '',
    lanId: '',
  }
  const env = useMPMEnv()
  const { t, i18n } = useTranslation()

  const { createPreferences, isLoading: isCreateLoading } =
    useCreatePreferences({
      lanId,
      env,
      accessToken: session?.accessToken ?? '',
    })

  const { updatePreferences, isLoading: isUpdateLoading } =
    useUpdatePreferences({
      lanId,
      env,
      accessToken: session?.accessToken ?? '',
      preferences,
    })

  const handleSuccess = (response: any) => {
    const newPreferences = {
      ...preferences,
      user_contexts: [response],
    }
    setPreferences(newPreferences)
  }

  const handleLanguageChange = (lang: string) => {
    if (language === lang) {
      return
    }
    setLanguage(lang)
    i18n.changeLanguage(lang)
    if (preferences.user_contexts.length === 0) {
      createPreferences(
        {
          ...preferences,
          language: lang,
        },
        {
          onSuccess: handleSuccess,
        },
      )
    } else {
      updatePreferences(
        {
          ...preferences,
          language: lang,
        },
        {
          onSuccess: handleSuccess,
        },
      )
    }
  }

  if (isCreateLoading) {
    return <h1>{t(messages.creating)}</h1>
  }
  if (isUpdateLoading) {
    return <h1>{t(messages.updating)}</h1>
  }

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <ErrorFallback
          error={error}
          resetErrorBoundary={resetErrorBoundary}
          heading={t('settings')}
        />
      )}
    >
      <Layout
        heading="settings"
        className="settings-content"
        isBottomNavDisplayed={false}
      >
        <Card className="hc-pa-md">
          <Heading size={4}>info</Heading>
          <Divider className="hc-pb-sm" />
          <Grid.Container justify="flex-start" align="center">
            <SplitRow
              icon={ProfileIcon}
              start={t(messages.you)}
              end={`${firstName} (${lanId})`}
            />
            <SplitRow
              icon={StoreIcon}
              start={t(messages.location)}
              end={`${location.shortName} ${location.name}`}
            />
            <SplitRow
              icon={InfoFilledIcon}
              start={t(messages.version)}
              end={`${process.env.REACT_APP_VERSION}`}
            />
            <SplitRow
              icon={TextStringIcon}
              start={t(messages.language)}
              end={
                <ButtonGroup>
                  <Button
                    className={`ghost ${language === 'en' ? 'lang-active' : ''}`}
                    onClick={() => {
                      handleLanguageChange('en')
                    }}
                  >
                    {t(messages.languageOptions.EN)}
                  </Button>
                  <Button
                    className={`ghost ${language === 'es' ? 'lang-active' : ''}`}
                    onClick={() => {
                      handleLanguageChange('es')
                    }}
                  >
                    {t(messages.languageOptions.ES)}
                  </Button>
                </ButtonGroup>
              }
            />
          </Grid.Container>
        </Card>
        <FloatingActionButtonContainer>
          <FloatingActionButton icon={HelpIcon}>
            {t(messages.help)}
          </FloatingActionButton>
          <FloatingActionButton icon={ChatIcon}>
            {t(messages.feedback)}
          </FloatingActionButton>
        </FloatingActionButtonContainer>
      </Layout>
    </ErrorBoundary>
  )
}
