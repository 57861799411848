import { useEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import { TitledFilteredTable } from 'components/titled-filtered-table/title-filtered-table'
import { useUser } from 'components/user-context'
import { UrlParams } from 'enums/url-params'
import { useMerchandise } from 'hooks/shortage/useMerchandise'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { getLocationFilters } from 'utils/api'
import { TableData } from 'utils/data'
import { useMPMEnv } from 'utils/env'
import { getTableFormat } from 'utils/table'

export const MerchandiseGroups = () => {
  const { t } = useTranslation()
  const [params] = useSearchParams()
  const { location } = useUser()
  const env = useMPMEnv()
  const { session } = useAuth()
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName

  const [drills, setDrills] = useState<string[]>([])

  const filters = getLocationFilters(currentLocation)
  const { data } = useMerchandise({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    dynamicSubPath: drills.join('|'),
    filters,
  })
  const widths = [150, ...getTableFormat(data?.headings?.length ?? 20, 90)]

  const [currentData, setCurrentData] = useState<TableData>()
  const [selectedCell, setSelectedCell] = useState<string>('')

  useEffect(() => {
    if (data) {
      setSelectedCell('')
      setCurrentData(data)
    }
  }, [data])

  if (!currentData) return null

  const handleClick = (selected: string) => {
    setSelectedCell(selected)
    const selectedIndex = currentData.rows.findIndex(
      (row) => row[0].value === selected,
    )
    const firstNonInteractiveIndex = currentData.rows.findIndex(
      (row) => row[0].is_link === false,
    )
    if (selectedIndex < firstNonInteractiveIndex) {
      setDrills([...drills].slice(0, selectedIndex))
    } else {
      setDrills([...drills, selected])
    }
  }

  return (
    <TitledFilteredTable
      title={t('shortage')}
      data={currentData}
      widths={widths}
      handleClick={handleClick}
      pinFirstColumn={true}
      selectedCell={selectedCell}
    />
  )
}
