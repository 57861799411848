import { useMemo } from 'react'
import { Session, UserInfo, AuthContext } from '@praxis/component-auth'

/**
 * This will mimic the authenticated state of praxis authentication. Used for
 * testing only. Note that this will cause all authenticated API calls to fail,
 * thus any required calls should be mocked out.
 * @param children The contents to render within the pretend authenticated context
 * @returns
 */
export const FakeAuthenticationProvider = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const session = useMemo(() => {
    const obj = Object.assign({
      clientId: 'test_ac',
      authorizationUrl: 'none',
      nonce: '',
      accessTokenType: 'Bearer',
      accessToken: () => 'ABC',
      identityToken: 'ABC',
      identity: {},
      config: {},
      validated: true,
      access: [],
      userInfo: {
        firstName: 'Test',
        lanId: 'a123456',
        lastName: 'User',
        fullName: 'Test User',
        accessToken: 'ABC',
      } as UserInfo,
      validate() {},
      isAuthenticated: true,
      isAuthorized: true,
      hasExpired: false,
    })
    return obj as unknown as Session
  }, [])

  return (
    <AuthContext.Provider
      value={{
        session: session,
        login: () => {},
        logout: () => {},
        isAuthenticated: () => true,
        isAuthorized: () => true,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
