import { Layout } from 'components/layout'
import { FallbackProps } from 'react-error-boundary'
import { ErrorAlert } from './error-alert'
import { Button } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { RefreshIcon } from '@enterprise-ui/icons'
import { messages } from './messages'

type ExtendedFallbackProps = FallbackProps & {
  heading?: string
  additionalInfo?: string
}

export const ErrorFallback: React.FC<ExtendedFallbackProps> = ({
  error,
  resetErrorBoundary,
  heading = 'Error',
}) => (
  <Layout heading={heading}>
    <ErrorAlert error={error} />
    <Button onClick={resetErrorBoundary} className="reset-error-boundary">
      <EnterpriseIcon icon={RefreshIcon} /> <span>{messages.retry}</span>
    </Button>
  </Layout>
)
