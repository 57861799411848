import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchPayrollLocationDailyData } from 'queries/payroll/location/location'
import { PayrollFetchProps } from 'types/api'
import { IResponse, apiNotEnabled } from 'utils/api'
import { TIME } from 'utils/misc'

interface PayrollLocationDailyFetchProps extends PayrollFetchProps {
  current_date: string
}
export function usePayrollLocationDaily({
  env,
  accessToken,
  location,
  current_date,
}: PayrollLocationDailyFetchProps): IResponse {
  const { tenMinutes } = TIME
  const enabled = current_date.length > 1
  const { data, isLoading, isError, refetch, remove, error } = useQuery(
    [QueryKeys.PAYROLL_LOCATION_DAILY, current_date],
    () =>
      fetchPayrollLocationDailyData({
        env,
        accessToken,
        location,
        current_date,
      }),
    {
      enabled,
      staleTime: tenMinutes,
      cacheTime: tenMinutes,
    },
  )

  if (!enabled) {
    return apiNotEnabled()
  }

  return { data, isLoading, isError, refetch, remove, error }
}
