import { useState } from 'react'
import { Tabs } from '@enterprise-ui/canvas-ui-react'
import { Layout } from 'components/layout'
import { useUser } from 'components/user-context'
import { getTabs } from './helpers'
import { Location } from './location'
import { Workgroup } from './workgroup'
import { useSearchParams } from 'react-router-dom'
import { Overtime } from './overtime/overtime'
import { UrlParams } from 'enums/url-params'
import { clearLocationParam } from 'utils/helpers'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/error-fallback'

export const PayrollPage = () => {
  const [params, setParams] = useSearchParams()
  const { location } = useUser()
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName
  window.onpopstate = (e) => {
    clearLocationParam({ params, setParams })
  }

  return <Payroll key={currentLocation} />
}

const Payroll = () => {
  const { t } = useTranslation()
  const [params] = useSearchParams()
  const { location } = useUser()
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName
  const tabs = getTabs(currentLocation)
  const [activeTab, setActiveTab] = useState<string>(
    getTabs(currentLocation)[0],
  )

  const handleTabSelect = (_event: Event, tab: { name: string }) =>
    setActiveTab(tab.name)

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <ErrorFallback
          error={error}
          resetErrorBoundary={resetErrorBoundary}
          heading={t('payroll')}
        />
      )}
    >
      <Layout heading={t('payroll')} className="hc-pa-none">
        <Tabs
          activeTab={activeTab}
          className="tabs hc-pv-dense"
          justified
          disableAdaptive={true}
          onTabSelect={handleTabSelect}
        >
          {tabs.map((tab: string) => (
            <Tabs.Item className="hc-bg-tabsItem" key={tab} name={tab}>
              {t(tab)}
            </Tabs.Item>
          ))}
          <Tabs.Content className="tabsContent" name={tabs[0]}>
            {activeTab === tabs[0] && <Location />}
          </Tabs.Content>
          <Tabs.Content className="tabsContent" name={tabs[1]}>
            {activeTab === tabs[1] && <Workgroup />}
          </Tabs.Content>
          <Tabs.Content className="tabsContent" name={tabs[2]}>
            {activeTab === tabs[2] && <Overtime />}
          </Tabs.Content>
        </Tabs>
      </Layout>
    </ErrorBoundary>
  )
}
