import './splashScreen.css'

export const SplashScreen = () => {
  return (
    <div className="splash-screen hc-bg-contrast-weak">
      <img
        className="splash-image"
        src={`${process.env.PUBLIC_URL}/MPMicon.ico`}
        alt="MPM Logo"
      />
    </div>
  )
}
