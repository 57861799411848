import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchUserContext } from '../../queries/user-context/user-context'
import { UserContextProps } from 'types/api'
import { IPreferencesResponse } from 'utils/api'
import { TIME } from 'utils/misc'

export function usePreferences({
  lanId,
  env,
  accessToken,
}: UserContextProps): IPreferencesResponse {
  const { tenMinutes } = TIME
  const { data, isLoading, isError, refetch, remove, error } = useQuery(
    [QueryKeys.USER_CONTEXT, lanId],
    () =>
      fetchUserContext({
        env,
        accessToken,
        filters: {
          user_id: lanId,
        },
      }),
    {
      staleTime: tenMinutes,
      cacheTime: tenMinutes,
    },
  )

  return { data, isLoading, isError, refetch, remove, error }
}
