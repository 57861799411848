import { useEnv } from '@praxis/component-runtime-env'

export type MPMPermissionsType = {
  [key: string]: string[]
}

export type MPMEnvType = {
  environment: string
  auth: {
    authorizationUrl: string
    clientId: string
    loginUrl: string
    logoutRedirect: string
  }
  permissionsByKPI: MPMPermissionsType
  baseUrl: string
  apiKey: string
  firefly: {
    appName: string
    eventManagerKey: string
    baseUrl: string
    url: string
  }
  firebase: {
    apiKey: string
    authDomain: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    appId: string
  }
}

const useMPMEnv = () => {
  const env = useEnv<MPMEnvType>()

  return env
}

export { useMPMEnv }
