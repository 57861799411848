import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchPayrollWorkgroupDrillData } from 'queries/payroll/workgroup/workgroup'
import { PayrollFetchProps } from 'types/api'
import { TIME } from 'utils/misc'

export function usePayrollWorkgroupDrill({
  env,
  accessToken,
  location,
  dynamicSubPath = '',
}: PayrollFetchProps) {
  const { tenMinutes } = TIME
  let dynamicUrl = ''
  if (dynamicSubPath) {
    dynamicUrl = '/WORKGROUP/' + dynamicSubPath
  }
  const { data, isLoading, isError, refetch, remove } = useQuery(
    [QueryKeys.PAYROLL_WORKGROUP_DRILL, location + dynamicSubPath],
    () =>
      fetchPayrollWorkgroupDrillData({
        env,
        accessToken,
        location,
        dynamicSubPath: dynamicUrl,
      }),
    {
      enabled: dynamicSubPath.length > 0,
      staleTime: tenMinutes,
      cacheTime: tenMinutes,
      retry: false,
    },
  )

  return { data, isLoading, isError, refetch, remove }
}
