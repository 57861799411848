import { ApiSubUrls, Apis } from 'enums/api-constants'
import { fetchGeneralData } from 'queries/fetchData'
import { StoreFactsData } from 'pages/store-facts/utils/store-facts'

export const fetchStoreFacts = fetchGeneralData<StoreFactsData>(
  `${ApiSubUrls[Apis.STORE_FACTS]}`,
)
export const fetchStoreLeadership = fetchGeneralData<any>(
  `${ApiSubUrls[Apis.GREENFIELD]}`,
)
