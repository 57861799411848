import { useState } from 'react'
import { useUser } from 'components/user-context'
import { UrlParams } from 'enums/url-params'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Tabs } from '@enterprise-ui/canvas-ui-react'
import { SafetyLagging } from './safety-lagging'
import { SafetyTotal } from './safety-total'
import { SafetyLeading } from './safety-leading'

export const SafetyNonStore = () => {
  const { t } = useTranslation()
  const [params] = useSearchParams()
  const { location } = useUser()
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName

  const tabs = ['lagging', 'total', 'leading']
  const [activeTab, setActiveTab] = useState<string>(tabs[0])

  const handleTabSelect = (_event: Event, tab: { name: string }) =>
    setActiveTab(tab.name)

  return (
    <>
      <Tabs
        activeTab={activeTab}
        className="tabs hc-pv-dense"
        justified
        disableAdaptive={true}
        onTabSelect={handleTabSelect}
      >
        {tabs.map((tab: string) => (
          <Tabs.Item className="hc-bg-tabsItem" key={tab} name={tab}>
            {t(tab)}
          </Tabs.Item>
        ))}
        <Tabs.Content className="tabsContent" name={tabs[0]}>
          {activeTab === tabs[0] && <SafetyLagging key={currentLocation} />}
        </Tabs.Content>
        <Tabs.Content className="tabsContent" name={tabs[1]}>
          {activeTab === tabs[1] && <SafetyTotal key={currentLocation} />}
        </Tabs.Content>
        <Tabs.Content className="tabsContent" name={tabs[2]}>
          {activeTab === tabs[2] && <SafetyLeading key={currentLocation} />}
        </Tabs.Content>
      </Tabs>
    </>
  )
}
